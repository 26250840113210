import React from 'react';

function App({message}) {

  return (
    <div> 
        Err 404
    </div>
  );
}

export default App;