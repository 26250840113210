import React from 'react';
import postavicka from "../img/knight.png";
import {motion} from "framer-motion"
import { useInView } from 'react-intersection-observer';
import TrailerCz from "../img/trailer_cz.mp4"
import TrailerEn from "../img/trailer_en.mp4"
import { NavLink } from 'react-router-dom';

import sosvel from "../img/partners/sosvel.png";

function App({messages, download}) {

  const { ref: ref, inView: inView } = useInView({ triggerOnce: false });
  
  const { ref: refAbout, inView: inViewAbout } = useInView({ triggerOnce: false });
  
  const { ref: refPartners, inView: inViewPartners } = useInView({ triggerOnce: false });

  return (
    <div> 
        <div className="home-top-info">
          <motion.div initial={{ x: -50, opacity: 0 }}animate={{ x: 0, opacity: 1 }} exit={{ x: -50, opacity: 0 }} transition={{ duration: 0.3 }}>
          <h1>{messages.home_company}<span>{messages.home_company_end}</span></h1>
          <h6>{messages.home_company}<span>{messages.home_company_end}</span></h6>
          <h2>{messages.home_abouttext}</h2>
          </motion.div>
          <motion.div className="home-top-image" initial={{ x: 50, opacity: 0 }}animate={{ x: 0, opacity: 1 }} exit={{ x: 50, opacity: 0 }} transition={{ duration: 0.3 }}>
            <div className="home-top-knight">
            <img src={postavicka} alt={messages.company + " Knight"}/>
            <h3>{messages.home_joinus}</h3>
            <button onClick={() => {download()}}>{messages.download}</button>
            </div>
          </motion.div>
          <div className="home-more-info-fix">
          <motion.div initial={{ y: 50, opacity: 0 }}animate={{ y: 0, opacity: 1 }} exit={{ y: 50, opacity: 0 }} transition={{ duration: 0.3 }}><h4>{messages.home_moreinfo}</h4></motion.div>
          <motion.div initial={{ y: 50, opacity: 0 }}animate={{ y: 0, opacity: 1 }} exit={{ y: 50, opacity: 0 }} transition={{ duration: 0.3 }}><h5>{messages.home_scrollinfo}</h5></motion.div>
          </div>
        </div>

        <div  className="home-trailer" ref={ref}>
          <motion.h1 ref={ref} initial={{ y: -50, opacity: 0 }} animate={inView ? { opacity: 1, y: 0 } : {}} exit={{ y: -50, opacity: 0 }} transition={{ duration: 0.3, delay: 0.1 }}>{messages.trailer_title}</motion.h1>
          <motion.h3 ref={ref} initial={{ y: -50, opacity: 0 }} animate={inView ? { opacity: 1, y: 0 } : {}} exit={{ y: -50, opacity: 0 }} transition={{ duration: 0.3, delay: 0.1 }}>{messages.trailer_title}</motion.h3>
          <motion.h2 ref={ref} initial={{ y: -80, opacity: 0 }} animate={inView ? { opacity: 1, y: 0 } : {}} exit={{ y: -80, opacity: 0 }} transition={{ duration: 0.3, delay: 0.1 }}>{messages.trailer_subtitle}</motion.h2>
          <motion.div ref={ref} initial={{ y: 50, opacity: 0 }} animate={inView ? { opacity: 1, y: 0 } : {}} exit={{ y: 50, opacity: 0 }} transition={{ duration: 0.3, delay: 0.1 }}>
          <video ref={ref} controls src={messages.data === "cz" ? TrailerCz : TrailerEn} type="video/mp4" />
           </motion.div>
        </div>

        <div  className="home-about">
          <motion.h1 ref={refAbout} initial={{ y: -50, opacity: 0 }} animate={inViewAbout ? { opacity: 1, y: 0 } : {}} exit={{ y: -50, opacity: 0 }} transition={{ duration: 0.3, delay: 0.1 }}>{messages.about_title}</motion.h1>
          <motion.h3 initial={{ y: -50, opacity: 0 }} animate={inViewAbout ? { opacity: 1, y: 0 } : {}} exit={{ y: -50, opacity: 0 }} transition={{ duration: 0.3, delay: 0.1 }}>{messages.about_title}</motion.h3>
          <motion.div ref={refAbout} className="home-about-text" initial={{ y: 50, opacity: 0 }} animate={inViewAbout ? { opacity: 1, y: 0 } : {}} exit={{ y: 50, opacity: 0 }} transition={{ duration: 0.3, delay: 0.1 }}>
            <p>{messages.about_text1}</p>
            <p>{messages.about_text2}</p>
            <p>{messages.about_text3}</p>
            <p>{messages.about_text4}</p>
          </motion.div>
        </div>

        <div  className="home-partners">
          <motion.h1 ref={refPartners} initial={{ y: -50, opacity: 0 }} animate={inViewPartners ? { opacity: 1, y: 0 } : {}} exit={{ y: -50, opacity: 0 }} transition={{ duration: 0.3, delay: 0.1 }}>{messages.partners_title}</motion.h1>
          <motion.h3 initial={{ y: -50, opacity: 0 }} animate={inViewPartners ? { opacity: 1, y: 0 } : {}} exit={{ y: -50, opacity: 0 }} transition={{ duration: 0.3, delay: 0.1 }}>{messages.partners_title}</motion.h3>
          <motion.h2 ref={refPartners} initial={{ y: -80, opacity: 0 }} animate={inViewPartners ? { opacity: 1, y: 0 } : {}} exit={{ y: -80, opacity: 0 }} transition={{ duration: 0.3, delay: 0.1 }}>{messages.partners_subtitle}</motion.h2>
          <motion.div className="partners-container" ref={refPartners} initial={{ y: 80, opacity: 0 }} animate={inViewPartners ? { opacity: 1, y: 0 } : {}} exit={{ y: 80, opacity: 0 }} transition={{ duration: 0.3, delay: 0.1 }}>
            <NavLink to="https://www.sosvel.cz/"><img src={sosvel} alt="Sosvel logo"/></NavLink>
          </motion.div>
        </div>
    </div>
  );
}

export default App;