import React, { useEffect, useState } from 'react';
import darklogo from "../img/dark-footlogo.png";
import lightlogo from "../img/light-footlogo.png";
import { NavLink } from 'react-router-dom';
import moon from "../img/moon.png";
import sun from "../img/sun.png";
import { AnimatePresence, motion } from 'framer-motion';
import { IoMenu, IoClose } from "react-icons/io5";

function App({ messages, theme, changeLang, changeTheme, download }) {
  
  const [langs, setLangs] = useState([]);
  const [showLang, setShowLang] = useState(false);
  const [menu, setMenu] = useState(false);
  const [isLargeScreen, setIsLargeScreen] = useState(window.innerWidth > 1500);

  useEffect(() => {
    const handleResize = () => {
      setIsLargeScreen(window.innerWidth > 1500);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    const files = ["cz.json", "en.json"];
    const fetchJsonFile = async (fileName) => {
      const response = await fetch(`${process.env.PUBLIC_URL}/lang/${fileName}`);
      const data = await response.json();
      return data;
    };

    const fetchAllNames = async () => {
      const allNames = await Promise.all(files.map(fetchJsonFile));
      setLangs(allNames);
    };

    fetchAllNames();
  }, []);

  const openLang = () => setShowLang(!showLang);
  const openMenu = () => setMenu(!menu);

  return (
    <motion.div
      className="Topbar"
      initial={{ top: -50, opacity: 0 }}
      animate={{ top: 0, opacity: 1 }}
      exit={{ top: -50, opacity: 0 }}
      transition={{ duration: 0.3 }}
    >
      <button onClick={openMenu} className="topbar-open-menu"><IoMenu /></button>
      {menu && <button onClick={openMenu} className="topbar-close-menu"><IoClose /></button>}
      <div className="Topline">
        <h1>{messages.rpgworld}</h1>
        <button onClick={changeTheme}>
          <img src={theme ? sun : moon} alt={`${messages.company} theme`} />
        </button>
        <button onClick={openLang}>
          <img src={`/lang/${messages.icon}`} alt={`${messages.company} lang`} />
        </button>
      </div>

      <AnimatePresence>
        {showLang && (
          <motion.div
            className="LangMenu"
            initial={{ top: 20, opacity: 0 }}
            animate={{ top: 50, opacity: 1 }}
            exit={{ top: 20, opacity: 0 }}
            transition={{ duration: 0.3 }}
          >
            {langs.map((item, index) => (
              <button onClick={() => { changeLang(item.data); openLang(); }} key={index}>
                <img src={`/lang/${item.icon}`} alt={`${item.company} lang`} />
                <h1>{item.name}</h1>
              </button>
            ))}
          </motion.div>
        )}
      </AnimatePresence>

      {isLargeScreen ? (
        <div className="Topmenu">
          <img src={theme ? lightlogo : darklogo} alt={`${messages.company} logo`} />
          <div>
              <NavLink to="/">{messages.home}</NavLink>
              <NavLink to="/about-project">{messages.project}</NavLink>
              <NavLink to="/contact">{messages.contact}</NavLink>
          </div>
          <button  onClick={() => {download()}}>{messages.download}</button>
        </div>
      ) : (
        <AnimatePresence>
          {menu && (
            <motion.div
              className="Topmenu"
              initial={{ top: 0, opacity: 0 }}
              animate={{ top: 0, opacity: 1 }}
              exit={{ top: 0, opacity: 0 }}
              transition={{ duration: 0.3 }}
            >
              <img src={theme ? lightlogo : darklogo} alt={`${messages.company} logo`} />
              <div>
                <NavLink onClick={() => {openMenu()}} to="/">{messages.home}</NavLink>
                <NavLink onClick={() => {openMenu()}} to="/about-project">{messages.project}</NavLink>
                <NavLink onClick={() => {openMenu()}} to="/contact">{messages.contact}</NavLink>
              </div>
              <button  onClick={() => {download()}}>{messages.download}</button>
            </motion.div>
          )}
        </AnimatePresence>
      )}
    </motion.div>
  );
}

export default App;
