import React from 'react';
import postavicka1 from "../img/loading/zombie1.png";
import postavicka2 from "../img/loading/zombie2.png";
import postavicka3 from "../img/loading/zombie3.png";
import postavicka4 from "../img/loading/zombie4.png";
import postavicka5 from "../img/loading/zombie5.png";

function App({ messages }) {

  return (
    <div className="loader">
        <div>
            <img src={postavicka1} alt={messages.company + " Loading"}/>
            <img src={postavicka2} alt={messages.company + " Loading"}/>
            <img src={postavicka3} alt={messages.company + " Loading"}/>
            <img src={postavicka4} alt={messages.company + " Loading"}/>
            <img src={postavicka5} alt={messages.company + " Loading"}/>
        </div>
      <h1>{messages.loading}</h1>
    </div>
  );
}

export default App;
