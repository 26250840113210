import "./App.css";
import "./montserrat.css";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import React, { useEffect, useRef } from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import { AnimatePresence, motion } from "framer-motion";
import { useState } from "react";
import Error from "./web/Error";

import Loading from "./components/loader";
import Home from "./web/Home";
import Topbar from "./components/topbar";
import Rosses from "./components/rosses";
import Castle from "./components/castle";
import Mountain from "./components/mountain";
import Footer from "./components/footer";

function App() {
  const location = useLocation();
  const [theme, setTheme] = useState(false);

  useEffect(() => {
    getTheme();
    getLang();

    if (theme === true) {
      document.body.style.backgroundColor = 'rgb(15, 15, 15)';
    } else {
      document.body.style.backgroundColor = 'rgb(240, 240, 240)';
    }
  }, [theme]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 2000);

    return () => clearTimeout(timer);
  }, []);

  const getTheme = () => {
    const themeInfo = localStorage.getItem("theme");
    if (themeInfo === null || themeInfo === "light") {
      setTheme(false);
    } else {
      setTheme(true);
    }
  };

  const getLang = () => {
    const langInfo = localStorage.getItem("lang");
    if (langInfo === null) {
      changeLang("cz");
    } else {
      changeLang(langInfo);
    }
  };

  const [messages, setMessages] = useState([]);

  const changeLang = async (lang) => {
    try {
      const response = await fetch(`/lang/${lang}.json`);
      const data = await response.json();
      setMessages(data);
    } catch (error) {
      console.error("Error loading messages:", error);
    }
  };

  const changeTheme = () => {
    const themeInfo = localStorage.getItem("theme");
    if (themeInfo === null || themeInfo === "light") {
      localStorage.setItem("theme", "dark");
      document.body.style.backgroundColor = 'rgb(15, 15, 15)';
      setTheme(true);
    } else {
      localStorage.setItem("theme", "light");
      document.body.style.backgroundColor = 'rgb(240, 240, 240)';
      setTheme(false);
    }
  };

  const changeLangData = (data) => {
    localStorage.setItem("lang", data);
    changeLang(data);
  };

  const [loading, setLoading] = useState(false);

  const download = () => {
    toast.error(messages.soon, { position: "top-right", autoClose: 5000 });
    }


    const [showMountains, setShowMountains] = useState([]);

    useEffect(() => {
      const shouldShowComponents = () => {
        const allowedPagesRegex = /^(\/(\/|$))/;
        return allowedPagesRegex.test(location.pathname);
      };
    
      setShowMountains(shouldShowComponents());
    }, [location.pathname]);

  return (
    <div className={theme ? "dark-theme" : "light-theme"}>
            <ToastContainer theme={theme ? "dark" : "light"} position="top-right" autoClose={5000} hideProgressBar={false} closeOnClick draggable pauseOnHover />
            <Topbar theme={theme} messages={messages} changeLang={changeLangData} changeTheme={changeTheme} download={download}/>

            <motion.div initial={{ opacity: 0 }}animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.3 }}><Rosses /></motion.div>
            {showMountains && <>
            <motion.div initial={{ opacity: 0 }}animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.3 }}><Castle /></motion.div>
            <motion.div initial={{ opacity: 0 }}animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.3 }}><Mountain /></motion.div>
            </>}

            <div className="main-body">
      <AnimatePresence mode="wait">
        {loading ? (
          <motion.div key="loading" initial={{ opacity: 1 }} animate={{ opacity: 1 }} exit={{ opacity: 0 }} transition={{ duration: 0.3 }}>
            <Loading messages={messages} />
          </motion.div>
        ) : (
              <Routes location={location} key={location.pathname}>   
                <Route path="/" element={<Home messages={messages} download={download}/>} />
                <Route path="*" element={<Error messages={messages}/>} />
              </Routes>
        )}
      </AnimatePresence>
      </div>
      <Footer message={messages} download={download}/>
    </div>
  );
}

export default App;
