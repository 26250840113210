import React from 'react';

function App() {

  return (
    <div  className="mountaint"> 
        <svg  viewBox="0 0 2000 2000">
            <g>
            <rect x="0" y="0" width="500" height="500" style={{ fill: "none" }}/>
            <g  transform="matrix(0.791112,0,0,0.791112,0,104.444)">
                <path d="M0.336,499.653L62.168,435.921C66.374,431.586 71.638,428.424 77.44,426.748L107.74,417.995C111.238,416.984 114.142,414.534 115.726,411.255L119.009,404.456C119.927,402.556 121.3,400.911 123.007,399.669L142.888,385.193C144.274,384.183 145.444,382.905 146.328,381.435L172.453,337.99C174.362,334.815 177.377,332.458 180.92,331.372L195.924,326.774L200.99,316.203C202.648,312.741 205.279,309.837 208.56,307.845L209.991,306.976C211.833,305.858 213.386,304.322 214.524,302.492L247.19,250L280.427,283.878C282.466,285.955 285.14,287.291 288.026,287.672L291.827,288.174C297.699,288.95 302.914,292.316 306.038,297.348L350.384,368.779L356.999,362.826L371.458,371.023C375.835,373.504 379.392,377.212 381.69,381.688L386.146,390.367L409.703,352.423C411.873,348.928 415.496,346.589 419.575,346.05L421.422,345.806C424.307,345.425 426.982,344.089 429.02,342.012L452.323,318.259L475.978,356.271C476.866,357.699 478.078,358.897 479.515,359.77L480.569,360.409C482.848,361.793 484.675,363.81 485.827,366.215L489.592,374.071L498.997,376.953C502.54,378.039 505.555,380.396 507.465,383.571L525.281,413.2C526.164,414.67 527.334,415.947 528.721,416.957L541.89,426.546C543.597,427.789 544.97,429.433 545.888,431.334L547.214,434.079C548.798,437.358 551.701,439.808 555.199,440.819L573.308,446.05C579.11,447.727 584.374,450.888 588.58,455.223L631.777,499.748L268.54,499.748L268.645,499.653L0.336,499.653ZM0.336,499.653L0,500L0,499.653L0.336,499.653ZM631.777,499.748L632.021,499.748L632.021,500L631.777,499.748Z"/>
            </g>
        </g>
        </svg>
    </div>
  );
}

export default App;