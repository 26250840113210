import React from 'react';
import { NavLink } from 'react-router-dom';
import logodark from "../img/dark.png";

function App({message, download}) {


  return (
    <div className="Footer"> 
        <h1>{message.full_company}</h1>
        <div className="Footer-left">
        <img src={logodark} alt={message.company + " logo"} />
        </div>
        <div className="Footer-right">
        <h3>{message.links}</h3>
        <NavLink to="/about-project"><button>{message.project}</button></NavLink>
        <NavLink to="/contact"><button>{message.contact}</button></NavLink>
        <button onClick={() => {download()}}>{message.download}</button>
        </div>
        <h2>© Copyright | {message.company}</h2>
        <h4>Designed by <span onClick={()=> window.open("https://www.mrlubau.eu", "_blank")}>Lubau</span></h4>
    </div>
  );
}

export default App;